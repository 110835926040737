import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import H1 from "../components/h1";
import Section from "../components/section";
import MagForm from "../components/blastmail";

const MagIndexPage = ({ location }) => {
  return (
    <Layout>
      <Seo title="無料メールマガジン登録のご案内" />
      <main>
        <H1>無料メールマガジン登録のご案内</H1>
        <div
          id="content"
          className="bg-gray-100 space-y-3 md:space-y-5 py-3 md:py-5"
        >
          <Section>
            <p>
              Mizuno Consultancy Holdings
              Ltd.では、中国ビジネス・トレンド、中国ビジネス規定、特集、会報から新刊のご案内、チェイス・チャイナ中国レポート紹介等を盛り込んだ無料メルマガ【Mizuno-CH
              中国ビジネス情報】ダイジェスト版を発行しています。
            </p>
            <div>
              メルマガのサンプルは
              <ul>
                <li>
                  <Link
                    to="/magbacknumber"
                    className="text-cyan-500 hover:text-cyan-400"
                  >
                    メルマガバックナンバー 中国ビジネス版
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cnvnmag"
                    className="text-cyan-500 hover:text-cyan-400"
                  >
                    メルマガバックナンバー 中国・ベトナムビジネス版
                  </Link>
                </li>
              </ul>
              にてご覧いただけます。
            </div>
            <p>
              ご登録希望の方は
              <strong>会社名、お名前、登録するメールアドレス</strong>
              をご記入の上、下記のフォームよりお申し込みください。
            </p>
            <MagForm />
          </Section>
        </div>
      </main>
    </Layout>
  );
};

export default MagIndexPage;
