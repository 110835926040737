import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const MagForm = () => {
  const [visible, setVisible] = useState(false);
  const [err, setErr] = useState(0);
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        setVisible(false);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [visible]);

  const {
    register,
    handleSubmit,
    //watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(
      "https://mch-functions.azurewebsites.net/api/CreateBlastmailUser?code=QMifZ322LqmMFf4KGJitA5zEoGCDll4XAJFlHZfR97YQxgFFEgauPA==",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          setVisible(true);
        } else {
          setErr(1);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        setErr(2);
        console.log(error);
      });
  };

  return (
    <form
      className="flex flex-col gap-2 md:gap-5"
      onSubmit={handleSubmit(onSubmit)}
    >
      <label className="flex flex-col md:flex-row items-center justify-center">
        <span className="w-full md:w-1/5 mb-1 md:mb-0">会社名</span>
        <input
          className={`
          ${errors.c2 ? "bg-cyan-200 " : ""}
            w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-cyan-300 focus:border-transparent`}
          type="text"
          placeholder="会社名"
          {...register("c2", { required: true, message: "Required" })}
        />
      </label>
      <label className="flex flex-col md:flex-row items-center justify-center">
        <span className="w-full md:w-1/5 mb-1 md:mb-0">お名前</span>
        <input
          className={`
            ${errors.c0 ? "bg-cyan-200 " : ""}
              w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-cyan-300 focus:border-transparent`}
          type="text"
          placeholder="お名前"
          {...register("c0", { required: true })}
        />
      </label>
      <label className="flex flex-col md:flex-row items-center justify-center">
        <span className="w-full md:w-1/5 mb-1 md:mb-0">メール</span>
        <input
          className={`
          ${errors.c15 ? "bg-cyan-200 " : ""}
            w-full md:w-3/5 focus:outline-none focus:ring-2 focus:ring-cyan-300 focus:border-transparent`}
          type="text"
          placeholder="my@email.com"
          {...register("c15", { required: true })}
        />
      </label>
      {errors.c2 || errors.c0 || errors.c15 ? (
        <span className="font-bold text-cyan-600 text-center">
          恐れ入りますが入力をお願いいたします。
        </span>
      ) : null}
      <div className="flex justify-center mt-5">
        <button
          className="w-full md:w-1/6 bg-cyan-800 hover:bg-cyan-700 text-white rounded-sm px-4 py-2 font-bold disabled:opacity-70"
          type="submit"
          disabled={visible}
        >
          送信
        </button>
      </div>
      {visible ? (
        <p className="text-center border-2 border-pine-500 mt-3 py-2 text-pine-600">
          メールマガジンの登録を完了しました。お申し込み誠にありがとうございました。
        </p>
      ) : null}
      {err !== 0 ? (
        <p className="text-center border-2 border-red-500 mt-3 py-2 text-red-600">
          {err === 1
            ? "このメールアドレスは既に登録されています。他のメールアドレスをご利用ください。"
            : "フォームの調子が悪いため、恐れ入りますがmag@mizuno-ch.comまでお問い合わせください。"}
        </p>
      ) : null}
    </form>
  );
};

export default MagForm;
