import React from "react";

const Section = ({ children }) => {
  return (
    <section>
      <div className="container mx-auto max-w-4xl px-5 md:px-10 py-10 md:py-20 space-y-5 md:space-y-10 bg-white">
        {children}
      </div>
    </section>
  );
};

export default Section;
